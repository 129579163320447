import ToggleActiveSwitch from "components/ToggleActiveSwitch/ToggleActiveSwitch";
import React, { useState, useContext } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridRowOrderChangeParams,
} from "@mui/x-data-grid-pro";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PriviewModal from "components/TemplatePriviewModal/TemplatePriviewModal";
import BasicPopover from "components/BasicPopover/BasicPopover";
import EditModal from "components/TemplateModals/EditModal";
import AddRowModal from "components/TemplateModals/AddRowModal";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Typography from "@mui/material/Typography";
import PreviewIcon from "icons/preview/previewIcon.svg";
import InfoIconEmpty from "icons/info-icon-empty/InfoOutlined.svg";
import "./ReportTemplateTable.scss";
import { getFromLocalStorage } from "services/storageInterection";
import { Switch } from "@mui/material";
import { UserDataContext } from "../../App";

function DeleteDataFieldDialog({ open, onClose, onDelete }: any) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Delete Data Field</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete this Data Field? You'll have to
          recreate it from scratch if you need it in the future.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color="primary">
          Cancel
        </Button>
        <Button onClick={onDelete} variant="contained" color="primary">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
}

function ActionCellTemplates({ data, onActionClick }: any) {
  return (
    <div className="action-cell">
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={"long-menu"}
        aria-expanded={"true"}
        aria-haspopup="true"
        onClick={(e: any) => onActionClick(e.currentTarget, data)}
      >
        <MoreVertIcon />
      </IconButton>
    </div>
  );
}

type ColumnDataType = {
  localName: string;
  name: string;
  dataType: string;
  minValue: number;
  maxValue: number;
  isMandatory: boolean;
  isMandatoryEMEA: boolean;
  order: number;
};

type backEndColumndDataType = "int" | "float" | "str";

type ReportTemplateType = {
  column_name: string; // Original column name
  translated_column_name: string; // Translated column name
  report_type: string; // Report type
  report_subtype: string; // Report subtype
  data_type: backEndColumndDataType; // Data type (e.g., int, string)
  is_nullable: boolean; // Indicates if the field is nullable
  is_duplicate_constraint: boolean; // Indicates if duplicate constraint is applied
  min_value: number; // Minimum value (if applicable)
  max_value: number; // Maximum value (if applicable)
  mandatory: boolean; // Indicates if the field is mandatory
  switcher_enabled_country: boolean; // Indicates if the switcher is enabled for country
  switcher_enabled_emea: boolean; // Indicates if the switcher is enabled for EMEA
  isEDF: boolean; // Indicates if it's an EDF field
  row_order: number; // Specifies the row order in the report
};

const columnsDataTypesDictionary = {
  int: "Number",
  str: "Text",
  float: "Decimal",
  bool: "Boolean",
  //  'date': 'Date'
};

//utils
function formatUpdatedDataForBackEnd(oldValue: any, newValue: any) {
  const result: any = { ...newValue };
  for (let key in oldValue) {
    result[`${key}_old`] = oldValue[key];
  }
  return result;
}
// utils

interface ReportTemplateTableInterface {
  data: Array<ReportTemplateType>;
  onRowChangePosition: any;
  onRowEdit: any;
  onAddNewRow: any;
  isTableDataLoaded: any;
  onDelete: any;
}

function ReportTempleteTableToolbar({ onAddNewRow, onPreview }: any) {
  return (
    <GridToolbarContainer
      sx={{
        minHeight: "38px",
        display: "flex",
        justifyContent: "space-between",
        padding: "5px 10px",
      }}
    >
      <div>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
        <Button
          onClick={onPreview}
          startIcon={<img src={PreviewIcon} alt={"preview-icon"} />}
        >
          Preview
        </Button>
      </div>
      <div className="toolbar-preview-section">
        <div className="toolbar-preview-section__naming-convention naming-convention">
          <img src={InfoIconEmpty} alt="info" />
          <p>File Naming Convention</p>
        </div>
        <Button
          variant="contained"
          onClick={onAddNewRow}
          sx={{ background: "rgba(0, 145, 223, 1)" }}
        >
          Add record{" "}
        </Button>
      </div>
    </GridToolbarContainer>
  );
}

function ReportTemplateTable({
  data,
  onRowUpdate,
  onAddNewRow,
  onDelete,
  isTableDataLoaded,
  reportType,
}: any) {
  const country = getFromLocalStorage("selectedCountry");
  const [cellPopUpcurrentTarget, setCellPopUpcurrentTarget] =
    useState<any>(null);
  const [rowData, setRowData] = useState<any>(null);
  const [isEditMode, setEditMode] = useState<boolean>(false);
  const [isDeliteMode, setDeliteMode] = useState<boolean>(false);
  const [isAddNewRowMode, setAddNewRowMode] = useState<boolean>(false);
  const [isPreviewMode, setPreviewMode] = useState<boolean>(false);
  const { userProfile } = useContext(UserDataContext);
  // const userProfile = { isEMEA: true };

  function handleActionClick(currentTarget: any, data: any) {
    setCellPopUpcurrentTarget(currentTarget);
    setRowData(data.row);
  }

  function handleOpenEditRowWindow() {
    setEditMode(true);
    setCellPopUpcurrentTarget(null);
  }

  function handleDeliteRowWindow() {
    setDeliteMode(true);
    setCellPopUpcurrentTarget(null);
  }

  function handleAddNewRow(data: any) {
    onAddNewRow(data);
    setAddNewRowMode(false);
  }

  function handleEditSave(updatedData: ReportTemplateType) {
    const columnData = { ...rowData, country };
    const columnDataAfterUpdate = {
      ...rowData,
      ...updatedData,
      country,
    };
    setEditMode(false);
    onRowUpdate([
      formatUpdatedDataForBackEnd(columnData, columnDataAfterUpdate),
    ]);
  }

  function handleToggleRow(originalData: ReportTemplateType, updatedData: any) {
    const columnData = { ...originalData, country };
    const columnDataAfterUpdate = {
      ...originalData,
      ...updatedData,
      country,
    };

    setEditMode(false);
    onRowUpdate([
      formatUpdatedDataForBackEnd(columnData, columnDataAfterUpdate),
    ]);
  }

  async function handleRowOrderChange(params: GridRowOrderChangeParams) {
    const { targetIndex, oldIndex } = params;
    const substitutedRow = { ...data[targetIndex] };
    substitutedRow.row_order = oldIndex + 1;
    const substituteRow = { ...data[oldIndex] };
    substituteRow.row_order = targetIndex + 1;
    const x = formatUpdatedDataForBackEnd(
      { ...data[targetIndex], country },
      { ...substitutedRow, country }
    );
    const y = formatUpdatedDataForBackEnd(
      { ...data[oldIndex], country },
      { ...substituteRow, country }
    );

    onRowUpdate([x, y]);
  }

  const isToggleDisabled = (
    isEmeaUser: boolean,
    rowData: ReportTemplateType
  ) => {
    if (isEmeaUser) {
      return !rowData.switcher_enabled_emea;
    } else {
      return !rowData.switcher_enabled_country;
    }
  };

  const isRowDeletable = (rowData: ReportTemplateType) => {
    if (rowData?.isEDF) {
      return false;
    } else {
      return true;
    }
  };

  const isRowEditable = (isEmeaUser: boolean, rowData: ReportTemplateType) => {
    if (rowData?.isEDF) {
      return isEmeaUser ? true : false;
    } else return true;
  };

  const columns = [
    {
      field: "row_order",
      headerName: "#",
      filterable: false,
      flex: 0.3,
    },
    {
      field: "column_name",
      headerName: "Field Name in Local Language",
      filterable: false,
      flex: 2.4,
    },
    {
      field: "translated_column_name",
      headerName: "Field Name in English",
      filterable: false,
      flex: 2.2,
    },
    {
      field: "data_type",
      headerName: "Data Type",
      filterable: false,
      valueGetter: (value: "int" | "float" | "str") => {
        return columnsDataTypesDictionary[value];
      },
      maxWidth: 112,
      flex: 0.9,
    },
    {
      field: "min_value",
      headerName: "Min Value",
      filterable: false,
      valueGetter: (value: any, row: ReportTemplateType) => {
        if (row.data_type === "str") {
          return " ";
        } else {
          return value;
        }
      },
      maxWidth: 120,
      flex: 1,
    },
    {
      field: "max_value",
      headerName: "Max Value",
      filterable: false,
      maxWidth: 120,
      valueGetter: (value: any, row: ReportTemplateType) => {
        if (row.data_type === "str") {
          return " ";
        } else {
          return value;
        }
      },
      flex: 1,
    },
    {
      field: "is_duplicate_constraint",
      headerName: "Duplicate Constraint",
      filterable: false,
      renderCell: (params: any) => (
        <Switch
          checked={params?.value}
          disabled={isToggleDisabled(userProfile.isEMEA, params?.row)}
          onChange={(event) =>
            handleToggleRow(params?.row, {
              is_duplicate_constraint: event.target.checked,
            })
          }
          size="small"
        />
      ),
      flex: 0.7,
    },
    {
      field: "mandatory",
      headerName: "Mandatory",
      filterable: false,
      renderCell: (params: any) => (
        <Switch
          checked={params?.value}
          disabled={isToggleDisabled(userProfile.isEMEA, params?.row)}
          onChange={(event) =>
            handleToggleRow(params?.row, { mandatory: event.target.checked })
          }
          size="small"
        />
      ),
      flex: 0.7,
    },
    {
      field: "actions",
      headerName: " ",
      flex: 0.4,
      filterable: false,
      renderCell: (params: any) => (
        <div>
          {isRowEditable(userProfile.isEMEA, params?.row) && (
            <ActionCellTemplates
              data={params}
              onActionClick={handleActionClick}
            />
          )}
        </div>
      ),
    },
  ];

  function handleAddNewColumn(data: any){
    onAddNewRow(data);
    setAddNewRowMode(false);
  }

  return (
    <div style={{ height: "calc(100vh - 180px)", width: "100%" }}>
      {isEditMode && (
        <EditModal
          isToggleDisabled={isToggleDisabled(userProfile.isEMEA, rowData)}
          isUserEMEA={userProfile.isEMEA}
          open={isEditMode}
          data={rowData}
          onClose={() => {
            setRowData(null);
            setEditMode(false);
          }}
          onSave={handleEditSave}
        />
      )}

      <DeleteDataFieldDialog
        open={isDeliteMode}
        onClose={() => setDeliteMode(false)}
        onDelete={() => {
          onDelete(rowData.order);
          setDeliteMode(false);
        }}
      />

      <PriviewModal
        open={isPreviewMode}
        reportType={reportType}
        onClose={() => setPreviewMode(false)}
        data={data}
      />

      <AddRowModal
        open={isAddNewRowMode}
        onClose={() => setAddNewRowMode(false)}
        onSave={handleAddNewColumn}
        order={data.length + 1}
        reportType={reportType}
      />

      <BasicPopover
        targetEvent={cellPopUpcurrentTarget}
        setTargetEvent={setCellPopUpcurrentTarget}
      >
        <List>
          <ListItem disablePadding>
            <ListItemButton
              sx={{ width: "160px", height: "36px" }}
              onClick={handleOpenEditRowWindow}
            >
              <ListItemText primary="Edit" />
            </ListItemButton>
          </ListItem>
          {isRowDeletable(rowData) && (
            <ListItem disablePadding>
              <ListItemButton
                sx={{ width: "160px", height: "36px" }}
                onClick={handleDeliteRowWindow}
              >
                <ListItemText primary="Delete" />
              </ListItemButton>
            </ListItem>
          )}
        </List>
      </BasicPopover>

      <DataGridPro
        getRowId={(row) => row.column_name}
        sx={{
          background: "white",
          borderTop: "none",
          maxWidth: "100%",
          overflowY: "scroll",
          borderTopRightRadius: 0,
          borderTopLeftRadius: 0,
          fontFamily: "Helvetica Neue",
          color: "#10384F",
          "& .MuiDataGrid-columnHeader, .MuiDataGrid-scrollbarFiller": {
            backgroundColor: "rgba(245, 245, 245, 1)",
          },
        }}
        rows={data}
        columns={columns}
        rowReordering
        onRowOrderChange={handleRowOrderChange}
        pagination
        loading={isTableDataLoaded}
        pageSizeOptions={[10, 15]}
        initialState={{
          pagination: { paginationModel: { pageSize: 10 } },
        }}
        slots={{
          toolbar: ReportTempleteTableToolbar,
        }}
        slotProps={{
          toolbar: {
            onAddNewRow: () => setAddNewRowMode(true),
            onPreview: () => setPreviewMode(true),
          },
        }}
      />
    </div>
  );
}

export default ReportTemplateTable;
