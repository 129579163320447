import React from "react";
import { useState, useRef } from "react";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Box,
  InputAdornment,
} from "@mui/material";
import { getFromLocalStorage } from "services/storageInterection";
import {
  TooltipContent,
  tooltipContentStyles,
  LightTooltip,
} from "components/TemplateModals/Tooltip";

// Function to get key by value
function getKeyByValue<Type>(object: any, value: any): Type | any {
  return Object.keys(object).find(key => object[key] === value);
}

const columnsDataTypesDictionary = {
  int: "Number",
  str: "Text",
  float: "Decimal",
  //  'date': 'Date'
};


type backEndColumndDataType = "int" | "float" | "str";

type NewColumnDataType = {
  country: string;
  column_name: string;
  translated_column_name: string;
  report_type: "Inventory" | "Sales" | "Finance" | "Other"; // Update with more specific string literals if necessary
  report_subtype: string;
  data_type: "int" | "str" | "float"; // Adjust based on possible values for `data_type`
  is_duplicate_constraint: boolean;
  min_value: number;
  max_value: number;
  mandatory: boolean;
  switcher_enabled_emea: boolean;
  switcher_enabled_country: boolean;
  isEDF: boolean;
  row_order: number;
};

type ReportTemplateColumnType = {
  column_name: string; // Original column name
  translated_column_name: string; // Translated column name
  report_type: string; // Report type
  report_subtype: string; // Report subtype
  data_type: backEndColumndDataType; // Data type (e.g., int, string)
  is_nullable: boolean; // Indicates if the field is nullable
  is_duplicate_constraint: boolean; // Indicates if duplicate constraint is applied
  min_value: number; // Minimum value (if applicable)
  max_value: number; // Maximum value (if applicable)
  mandatory: boolean; // Indicates if the field is mandatory
  switcher_enabled_country: boolean; // Indicates if the switcher is enabled for country
  switcher_enabled_emea: boolean; // Indicates if the switcher is enabled for EMEA
  isEDF: boolean; // Indicates if it's an EDF field
  row_order: number; // Specifies the row order in the report
};


function AddRowModal({ open, onClose, onSave, order, reportType }: any) {
  const selectedCountry = getFromLocalStorage("selectedCountry");
  const defaultDataType = "Text";
  const [dataTypeState, setDataType] = useState<string>(defaultDataType);
  const columnNameRef = useRef<any>();
  const translatedColumnNameRef = useRef<any>();
  const minValueRef = useRef<any>();
  const maxValueRef = useRef<any>();
  const isMandatoryRef = useRef<any>();

  function handleSaveUpdate() {
    const dataTypeForBackEnd = getKeyByValue<backEndColumndDataType>(columnsDataTypesDictionary, dataTypeState);

    const newColumnData: NewColumnDataType = {
    "country": selectedCountry,
    "column_name": columnNameRef.current,
    "translated_column_name": translatedColumnNameRef.current,
    "report_type": reportType,
    "report_subtype": "default",
    "data_type":  dataTypeForBackEnd,
    "is_duplicate_constraint": false,
    "min_value":  dataTypeState === "Number" || dataTypeState === "Decimal" ? minValueRef.current : 0,
    "max_value": dataTypeState === "Number" || dataTypeState === "Decimal" ? maxValueRef.current : 100,
    "mandatory": isMandatoryRef.current,
    "switcher_enabled_emea": true,
    "switcher_enabled_country": true,
    "isEDF": false,
    "row_order": order
    }

    onSave(newColumnData);
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ "& .MuiDialog-paper": { width: 445 } }}
    >
      <DialogTitle>Add Data Field</DialogTitle>
      <Divider />
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            padding: "10px",
            "& .MuiInputBase-input, & .MuiButton-outlined": {
              fontFamily: "Helvetica Neue",
              color: "#10384F",
            },
            "& .MuiButton-outlined": {
              borderColor: "rgba(0, 0, 0, 0.42)",
            },
          }}
        >
          <TextField
            label="Field Name in English"
            variant="outlined"
            onChange={(e: any) =>
              (translatedColumnNameRef.current = e.target.value)
            }
            fullWidth
          />

          <TextField
            label="Field Name in Local Language"
            variant="outlined"
            onChange={(e: any) => (columnNameRef.current = e.target.value)}
            fullWidth
          />

          <TextField
            label="Data Type"
            variant="outlined"
            defaultValue={defaultDataType}
            fullWidth
            select
            onChange={(e: any) => setDataType(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment sx={{ marginRight: "8px" }} position="end">
                  <LightTooltip title={<TooltipContent />} arrow>
                    <IconButton>
                      <InfoOutlinedIcon />
                    </IconButton>
                  </LightTooltip>
                </InputAdornment>
              ),
            }}
          >
            {["Number", "Text", "Decimal"].map((option: string) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          {(dataTypeState === "Number" || dataTypeState === "Decimal") && (
            <Box sx={{ display: "flex", gap: "16px" }}>
              <TextField
                label="Min Value"
                variant="outlined"
                fullWidth
                onChange={(e: any) => (minValueRef.current = e.target.value)}
              />
              <TextField
                label="Max Value"
                variant="outlined"
                fullWidth
                onChange={(e: any) => (maxValueRef.current = e.target.value)}
              />
            </Box>
          )}
          <FormControlLabel
            onChange={(e: any) => {
              isMandatoryRef.current = e.target.checked;
            }}
            control={<Switch defaultChecked={false} />}
            label="Set as Mandatory"
          />
        </Box>
      </DialogContent>
      <Divider />
      <Box
        fontFamily={"Helvetica Neue"}
        display="flex"
        justifyContent="flex-end"
        p={2}
      >
        <Button
          sx={{
            borderColor: "rgba(0, 0, 0, 0.42)",
            color: "#10384F",
            fontFamily: "inherit",
          }}
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ ml: 2, fontFamily: "inherit" }}
          onClick={handleSaveUpdate}
        >
          Save
        </Button>
      </Box>
    </Dialog>
  );
}

export default AddRowModal;
