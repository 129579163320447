import { useState, useEffect, useRef } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControlLabel,
  Switch,
  Button,
  Box,
  InputAdornment,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { TooltipContent, tooltipContentStyles, LightTooltip } from "components/TemplateModals/Tooltip";

type backEndColumndDataType = "int" | "float" | "str";

type ReportTemplateColumnType = {
  column_name: string; // Original column name
  translated_column_name: string; // Translated column name
  report_type: string; // Report type
  report_subtype: string; // Report subtype
  data_type: backEndColumndDataType; // Data type (e.g., int, string)
  is_nullable: boolean; // Indicates if the field is nullable
  is_duplicate_constraint: boolean; // Indicates if duplicate constraint is applied
  min_value: number; // Minimum value (if applicable)
  max_value: number; // Maximum value (if applicable)
  mandatory: boolean; // Indicates if the field is mandatory
  switcher_enabled_country: boolean; // Indicates if the switcher is enabled for country
  switcher_enabled_emea: boolean; // Indicates if the switcher is enabled for EMEA
  isEDF: boolean; // Indicates if it's an EDF field
  row_order: number; // Specifies the row order in the report
};

const columnsDataTypesDictionary = {
  int: "Number",
  str: "Text",
  float: "Decimal",
  //  'date': 'Date'
};

function getKeyForValue(obj: any, value: string) {
  return Object.keys(obj).find((key) => obj[key] === value);
}

function EditModal({
  open,
  onClose,
  onSave,
  data,
  isUserEMEA,
  isToggleDisabled,
}: any) {
  // "translated_column_name" is untcachable!!!

  const {
    translated_column_name: translatedColumnName,
    column_name: columnName,
    data_type: dataType,
    mandatory: isMandatory,
    row_order: order,
    max_value: maxValue,
    min_value: minValue,
    isEDF,
  }: ReportTemplateColumnType = data;

  const [dataTypeState, setDataType] = useState<string>(dataType);
  const columnNameRef = useRef<any>();
  const translatedColumnNameRef = useRef<any>();
  const minValueRef = useRef<any>();
  const maxValueRef = useRef<any>();
  const isMandatoryRef = useRef<any>();

  useEffect(() => {
    columnNameRef.current = columnName;
    translatedColumnNameRef.current = translatedColumnName;
    minValueRef.current = minValue;
    maxValueRef.current = maxValue;
    isMandatoryRef.current = isMandatory;
    setDataType(columnsDataTypesDictionary[dataType]);

  }, []);

  function handleSaveUpdate() {
    onSave({
      column_name: columnNameRef.current,
      translated_column_name: translatedColumnNameRef.current,
      data_type: dataTypeState,
      min_value: minValueRef.current,
      max_value: maxValueRef.current,
      mandatory: isMandatoryRef.current,
    });
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      sx={{ "& .MuiDialog-paper": { width: 445 } }}
    >
      <DialogTitle>Edit</DialogTitle>
      <Divider />
      <DialogContent>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            padding: "10px",
            "& .MuiInputBase-input, & .MuiButton-outlined": {
              fontFamily: "Helvetica Neue",
              color: "#10384F",
            },
            "& .MuiButton-outlined": {
              borderColor: "rgba(0, 0, 0, 0.42)",
            },
          }}
        >
          <TextField
            disabled={isEDF}
            label="Field Name in English"
            variant="outlined"
            defaultValue={translatedColumnName}
            onChange={(e: any) =>
              (translatedColumnNameRef.current = e.target.value)
            }
            fullWidth
          />

          <TextField
            disabled={isEDF && !isUserEMEA}
            label="Field Name in Local Language"
            variant="outlined"
            defaultValue={columnName}
            onChange={(e: any) => (columnNameRef.current = e.target.value)}
            fullWidth
          />

          <TextField
            disabled={isEDF}
            label="Data Type"
            variant="outlined"
            fullWidth
            defaultValue={columnsDataTypesDictionary[dataType]}
            select
            onChange={(e: any) => setDataType(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment sx={{ marginRight: "8px" }} position="end">
                  <LightTooltip title={<TooltipContent />} arrow>
                    <IconButton>
                      <InfoOutlinedIcon />
                    </IconButton>
                  </LightTooltip>
                </InputAdornment>
              ),
            }}
          >
            {["Number", "Text", "Decimal"].map((option: string) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
          {(dataTypeState === "Number" || dataTypeState === "Decimal") && (
            <Box sx={{ display: "flex", gap: "16px" }}>
              <TextField
                disabled={isEDF}
                label="Min Value"
                variant="outlined"
                fullWidth
                onChange={(e: any) => (minValueRef.current = e.target.value)}
                defaultValue={minValue}
              />
              <TextField
                disabled={isEDF}
                label="Max Value"
                variant="outlined"
                fullWidth
                onChange={(e: any) => (maxValueRef.current = e.target.value)}
                defaultValue={maxValue}
              />
            </Box>
          )}
          <FormControlLabel
            disabled={isToggleDisabled}
            onChange={(e: any) => {
              isMandatoryRef.current = e.target.checked;
            }}
            control={<Switch defaultChecked={isMandatory} />}
            label="Set as Mandatory"
          />
        </Box>
      </DialogContent>
      <Divider />
      <Box
        fontFamily={"Helvetica Neue"}
        display="flex"
        justifyContent="flex-end"
        p={2}
      >
        <Button
          sx={{
            borderColor: "rgba(0, 0, 0, 0.42)",
            color: "#10384F",
            fontFamily: "inherit",
          }}
          variant="outlined"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ ml: 2, fontFamily: "inherit" }}
          onClick={handleSaveUpdate}
        >
          Save
        </Button>
      </Box>
    </Dialog>
  );
}

export default EditModal;
